export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'BMW',
  hostName: 'staging.analytics.bmwdealerprograms.com',
  environmentName: 'staging',
  appName: 'BMW Digital Analytics',
  ssoPassword: 'ShiftDigital#1',
  applicationInsights: {
    instrumentationKey: 'da2a5a6b-9daa-4db1-9785-fa730d41283e'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#384752', '#52616C', '#05141F', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://bmwus-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://bmwus-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://bmwus-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/bmw-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: []
};
